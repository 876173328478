import React, { useContext, useEffect, useState } from 'react'
import formatDate, { checkPnr } from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import GridSingles from '../../atoms/Grid/GridSingles'
import GridQuadruple from '../../atoms/Grid/GridQuadruple'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import InputText from '../../molecules/Input/InputText'
import InputDate from '../../molecules/Input/InputDate'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import CheckBox from '../../atoms/Check/CheckBox'
import GridQuintuplets from '../../atoms/Grid/GridQuintuplets'
import { existingProcessValidator, newProcessValidator } from './validator'
import { updateFormVals } from '../../../helpers/update'
import DropdownSearch from '../../molecules/StyledSelect/DropdownSearch'

const LeaveForm = ({ formVals, setFormVals, errors, setErrors, disabled = false }) => {
  const [update, setUpdate] = useState('init')
  const [options, setOptions] = useState([])
  const [searchString, setSearchString] = useState(formVals.nameOfEmployee ?? '')
  var { user } = useContext(SimpleAuthContext)

    // This effect runs whenever the input values change and checks if any of the fields have a value.
    useEffect(() => {
      if (
        formVals.fromDateOtherParentLeave || 
        formVals.toDateOtherParentLeave || 
        formVals.otherParentLeavePercent
      ) {
        setUpdate({ id: "otherParentLeave", value: true });
      }
         if (
        !formVals.fromDateOtherParentLeave && 
        !formVals.toDateOtherParentLeave && 
        !formVals.otherParentLeavePercent
      ) {
        setUpdate({ id: "otherParentLeave", value: false });
      }
    }, [formVals.fromDateOtherParentLeave, formVals.toDateOtherParentLeave, formVals.otherParentLeavePercent]);

    useEffect(() => {
      if (
        formVals.fromDateFromForsKassan || 
        formVals.toDateFromForsKassan || 
        formVals.fromForsKassanPercent
      ) {
        setUpdate({ id: "leaveFromForsKassan", value: true });
      }
      if (
        !formVals.fromDateFromForsKassan &
        !formVals.toDateFromForsKassan & 
        !formVals.fromForsKassanPercent
      ) {
        setUpdate({ id: "leaveFromForsKassan", value: false });
      }
    }, [formVals.fromDateFromForsKassan, formVals.toDateFromForsKassan, formVals.fromForsKassanPercent]);

  useEffect(() => {
    const token = localStorage.getItem('token')
    async function fetcher() {
      let data = await requestMaker(
        user,
        'POST',
        "employee/searchemployees",
        token,
        searchString
      )
      setOptions(data)
    }

    if (!!searchString && !['', formVals.nameOfEmployee].includes(searchString))
      fetcher()

  }, [searchString])

  useEffect(() => {
    if (!formVals)
      return
    var e
    if (!disabled)
      e = newProcessValidator(formVals)
    else
      e = existingProcessValidator(formVals)
    setErrors(e)
  }, [formVals])

  useEffect(() => {
    if (update == 'init') {
      if (formVals.nameOfEmployee === null && formVals.emailOfEmployee === null) {
        const updatedFormVals = { ...formVals, nameOfEmployee: user.firstName + ' ' + user.lastName, emailOfEmployee: user.email }
        setFormVals(updatedFormVals)
      }
      setSearchString(user.firstName + ' ' + user.lastName)
      return
    }

    if (update.id === 'additionalInformation') {
      const updatedInfo = { ...formVals.additionalInformation, ...update.value }
      const updatedFormVals = { ...formVals, additionalInformation: updatedInfo }
      setFormVals(updatedFormVals)
      return
    }
    if (update.isSearchDropdown) {
      var vals = { ...formVals }
      vals.nameOfEmployee = update.value.label
      vals.emailOfEmployee = update.value.email
      vals.employmentNumber = update.value.employeeNumber
      setFormVals(vals)
      return
    }
    updateFormVals(update, formVals, setFormVals)
  }, [update])

  return (
    <>
      <Grid title={"Användarinformation"} disabled={disabled} errorMsgState={errors.phoneNumberDuringLeave} >
        {disabled ?
          <InputText
            label={"Namn på sökande"}
            text={formVals.nameOfEmployee}
          />
          :
          <DropdownSearch
            label={"Namn på den sökande"}
            searchString={searchString}
            setSearchString={(e) => { setSearchString(e) }}
            options={options}
            placeholder={"Välj sökandes namn"}
            onSelect={(e) => {
              if (e)
                setUpdate({ value: e, isSearchDropdown: true })
            }}
          />
        }
        <InputText
          id={'email'}
          label={"Epost"}
          text={formVals.emailOfEmployee}
        />
        <InputText
          id={'city'}
          label={"Ort"}
          text={formVals.city}
          onChange={(e) => { setUpdate({ id: "city", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'employmentNumber'}
          label={"Anställningsnr"}
          text={formVals.employmentNumber}
        />
        <InputText
          id={'phoneNumberDuringLeave'}
          label={"Telefonnummer under ledighet"}
          text={formVals.phoneNumberDuringLeave}
          onChange={(e) => { setUpdate({ id: "phoneNumberDuringLeave", value: e }) }}
          disabled={disabled}
        />
      </Grid>
      <GridSingles title={"Föräldrarledighet"}
        disabled={disabled}>
        <h6>
          OBS! Vid omfattning mindre än 100% ska schema anges i
          upplysningsfältet nedan!
        </h6>
      </GridSingles>
      <Grid errorMsgState={errors.kidPersonalNo}>
        <InputDate
          id={'kidPersonalNo'}
          label={"Ange barnets födelsedata (ÅÅÅÅ-MM-DD)"}
          text={formVals.kidPersonalNo}
          onChange={(e) => { setUpdate({ id: "kidPersonalNo", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'estimatedBirthday'}
          label={"Ange beräknad nedkomst"}
          text={formatDate(formVals.estimatedBirthday)}
          onChange={(e) => { setUpdate({ id: "estimatedBirthday", value: e }) }}
          disabled={disabled}
        />
      </Grid>
      <GridQuadruple disabled={disabled} errorMsgState={errors.parentalLeave}>
        <CheckBox
          label={'Föräldraledighet/ Ersättning från Försäkringskassan'}
          value={"leaveFromForsKassan"}
          isChecked={!!formVals.leaveFromForsKassan}
          onChange={(e) => setUpdate({ id: "leaveFromForsKassan", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateFromForsKassan'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateFromForsKassan)}
          onChange={(e) => { setUpdate({ id: "fromDateFromForsKassan", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateFromForsKassan'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateFromForsKassan)}
          onChange={(e) => { setUpdate({ id: "toDateFromForsKassan", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'fromForsKassanPercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.fromForsKassanPercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "fromForsKassanPercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={'Annan föräldraledighet, ange orsak under "Upplysningar"'}
          value={"otherParentLeave"}
          isChecked={!!formVals.otherParentLeave}
          onChange={(e) => setUpdate({ id: "otherParentLeave", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateOtherParentLeave'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateOtherParentLeave)}
          onChange={(e) => { setUpdate({ id: "fromDateOtherParentLeave", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateOtherParentLeave'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateOtherParentLeave)}
          onChange={(e) => { setUpdate({ id: "toDateOtherParentLeave", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'otherParentLeavePercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.otherParentLeavePercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "otherParentLeavePercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={'Begär föräldralön enligt företagets policy'}
          value={"requestLeaveFromCompanyPolicy"}
          isChecked={!!formVals.requestLeaveFromCompanyPolicy}
          onChange={(e) => setUpdate({ id: "requestLeaveFromCompanyPolicy", value: e.target.checked })}
        />
      </GridQuadruple>
      <GridQuadruple title={"Tjänstledighet"} errorMsgState={errors.otherLeave}
        disabled={disabled}>
        <CheckBox
          label={"Fackligt uppdrag"}
          value={"unionAssigment"}
          isChecked={!!formVals.unionAssigment}
          onChange={(e) => setUpdate({ id: "unionAssigment", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateUnionAssigment'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateUnionAssigment)}
          onChange={(e) => { setUpdate({ id: "fromDateUnionAssigment", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateUnionAssigment'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateUnionAssigment)}
          onChange={(e) => { setUpdate({ id: "toDateUnionAssigment", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'unionAssigmentPercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.unionAssigmentPercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "unionAssigmentPercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={'Närståendevård (beslut från Försäkringskassan bifogas)'}
          value={"closePersonCare"}
          isChecked={!!formVals.closePersonCare}
          onChange={(e) => setUpdate({ id: "closePersonCare", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateClosePersonCare'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateClosePersonCare)}
          onChange={(e) => { setUpdate({ id: "fromDateClosePersonCare", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateClosePersonCare'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateClosePersonCare)}
          onChange={(e) => { setUpdate({ id: "toDateClosePersonCare", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'closePersonCarePercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.closePersonCarePercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "closePersonCarePercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={"Militärtjänstgöring (inkallelseorder bifogas)"}
          value={"militaryService"}
          isChecked={!!formVals.militaryService}
          onChange={(e) => setUpdate({ id: "militaryService", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateMilitaryService'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateMilitaryService)}
          onChange={(e) => { setUpdate({ id: "fromDateMilitaryService", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateMilitaryService'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateMilitaryService)}
          onChange={(e) => { setUpdate({ id: "toDateMilitaryService", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'militaryServicePercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.militaryServicePercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "militaryServicePercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={'Studier (antagningsbevis bifogas)'}
          value={"study"}
          isChecked={!!formVals.study}
          onChange={(e) => setUpdate({ id: "study", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateStudy'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateStudy)}
          onChange={(e) => { setUpdate({ id: "fromDateStudy", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateStudy'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateStudy)}
          onChange={(e) => { setUpdate({ id: "toDateStudy", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'studyPercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.studyPercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "studyPercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={'Permission, ange orsak under "Upplysningar"'}
          value={"permission"}
          isChecked={!!formVals.permission}
          onChange={(e) => setUpdate({ id: "permission", value: e.target.checked })}
        />
        <InputDate
          id={'fromDatePermission'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDatePermission)}
          onChange={(e) => { setUpdate({ id: "fromDatePermission", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDatePermission'}
          label={"T.o.m"}
          text={formatDate(formVals.toDatePermission)}
          onChange={(e) => { setUpdate({ id: "toDatePermission", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'permissionPercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.permissionPercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "permissionPercent", value: v })
          }}
          disabled={disabled}
        />
        <CheckBox
          label={'Annan ledighet, ange orsak under "Upplysningar"'}
          value={"leaveOfAbsence"}
          isChecked={!!formVals.leaveOfAbsence}
          onChange={(e) => setUpdate({ id: "leaveOfAbsence", value: e.target.checked })}
        />
        <InputDate
          id={'fromDateLeaveOfAbsence'}
          label={"Fr.o.m"}
          text={formatDate(formVals.fromDateLeaveOfAbsence)}
          onChange={(e) => { setUpdate({ id: "fromDateLeaveOfAbsence", value: e }) }}
          disabled={disabled}
        />
        <InputDate
          id={'toDateLeaveOfAbsence'}
          label={"T.o.m"}
          text={formatDate(formVals.toDateLeaveOfAbsence)}
          onChange={(e) => { setUpdate({ id: "toDateLeaveOfAbsence", value: e }) }}
          disabled={disabled}
        />
        <InputText
          id={'leaveOfAbsencePercent'}
          label={"Omfattning av ledighet i procent"}
          text={formVals.leaveOfAbsencePercent}
          onChange={(e) => {
            var v
            if (e)
              v = isNaN(e) ? e : parseInt(e)
            setUpdate({ id: "leaveOfAbsencePercent", value: v })
          }}
          disabled={disabled}
        />
      </GridQuadruple>
      <GridQuintuplets title={"Övriga uppgifter (Jag har)"}
        disabled={disabled}>
        <CheckBox
          label={'Tjänstebil'}
          value={"Tjänstebil"}
          isChecked={formVals.additionalInformation?.['Tjänstebil'] === true}
          onChange={(e) => setUpdate({ id: "additionalInformation", value: { 'Tjänstebil': e.target.checked } })
          }
        />
        <CheckBox
          label={'Parkeringsförmån'}
          value={"Parkeringsförmån"}
          isChecked={formVals.additionalInformation?.['Parkeringsförmån'] === true}
          onChange={(e) => setUpdate({ id: "additionalInformation", value: { 'Parkeringsförmån': e.target.checked } })
          }
        />
        <CheckBox
          label={'Avdrag för konstföreningen'}
          value={"Avdrag för konstföreningen"}
          isChecked={formVals.additionalInformation?.['Avdrag för konstföreningen'] === true}
          onChange={(e) => setUpdate({ id: "additionalInformation", value: { 'Avdrag för konstföreningen': e.target.checked } })
          }
        />
        <CheckBox
          label={'Löneväxling'}
          value={"Löneväxling"}
          isChecked={formVals.additionalInformation?.['Löneväxling'] === true}
          onChange={(e) => setUpdate({ id: "additionalInformation", value: { 'Löneväxling': e.target.checked } })
          }
        />
        <CheckBox
          label={'Frivillig gruppförsäkring'}
          value={"Frivillig gruppförsäkring"}
          isChecked={formVals.additionalInformation?.['Frivillig gruppförsäkring'] === true}
          onChange={(e) => setUpdate({ id: "additionalInformation", value: { 'Frivillig gruppförsäkring': e.target.checked } })
          }
        />
      </GridQuintuplets>
      <GridSingles
        disabled={disabled}
      >
        <InputTextArea
          id={'otherInformation'}
          title={'Övriga upplysningar / Schemas'}
          onChange={(e) => {
            setUpdate({ id: "otherInformation", value: e })
          }}
          text={formVals.otherInformation}
        />
      </GridSingles>
    </>
  )
}

export default LeaveForm