import React, { useContext, useEffect, useState } from 'react'
import Row from '../../atoms/Row/Row'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import BaseForm from '../BaseForm/BaseForm'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { requestAccessToken } from '../../../helpers/requestAccessToken'
import Colors from '../../../assets/Color'
import DecisionMaker from '../../../assets/DecisionMaker'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputText from '../../molecules/Input/InputText'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import { updateFormVals } from '../../../helpers/update'
import VehicleForm from '../VehicleForm/VehicleForm'
import Grid from '../../atoms/Grid/Grid'
import GridTriple from '../../atoms/Grid/GridTriple'
import formatDate from '../../../helpers/formatters'
import Attachment from '../../molecules/Attachment/Attachment'
import FileUploadInput from '../../molecules/FileUploadInput/FileUploadInput'
import { isCompleted, isFurtherInfoStep, isStep, stepIndicatorsProps } from '../../../helpers/formFunctions'
import FormButtons from '../FormButtons/FormButtons'
import FormButtonsWithOverride from "../FormButtons/FormButtonsWithOverride";


const VehicleExisting = ({ id }) => {
  const [update, setUpdate] = useState('init')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [dataFetch, setDataFetch] = useState()
  const [modalMessage, setModalMessage] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)
  const [formVals, setFormVals] = useState()
  const [errors, setErrors] = useState('')
  const [residualValue, setResidualValue] = useState('')
  const [showAdmin, setShowAdmin] = useState(false);
  const [dataFromFetch, setDataFromFetch] = useState({});

  const navigate = useNavigate()
  const { instance, accounts } = useMsal()
  var { user } = useContext(SimpleAuthContext)

  useEffect(() => {
    if (!dataFetch) return
    var vals = { ...dataFetch.data }
    setResidualValue(dataFetch?.data?.residualValue ?? '')
    setFormVals(vals)
  }, [dataFetch])

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        "GET",
        "User/GetUserRole",
        localStorage.getItem("token")
      );
      if (data) {
        setShowAdmin(true);
      }
      setDataFromFetch(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      requestAccessToken(instance, accounts)
      token = localStorage.getItem('token')
    }
    async function fetcher() {
      setIsLoading(true)
      const dataFromRequest = await requestMaker(
        user,
        'GET',
        `process/${id}`,
        token,
      )
      setIsLoading(false)
      if (dataFromRequest?.errorMessage === 'error') {
        setModalIsOpen(true)
        setModalMessage(dataFromRequest?.result?.message)
      }
      if (!dataFromRequest) navigate('/')
      setDataFetch(dataFromRequest)
    }
    fetcher()
  }, [navigate, id, /*accounts, Put back when using MSAL */ instance])

  useEffect(() => {
    if (update == 'init')
      return

    updateFormVals(update, formVals, setFormVals)
  }, [update])

  const removeExistingAttachment = (props) => {
    let attachmentId = props.target.id

    let attachmentsObj = dataFetch.attachments.filter(
      (attachment) => !attachment.filename.endsWith('@' + attachmentId),
    )
    setDataFetch({ ...dataFetch, attachments: attachmentsObj })
  }
  return (
    <BaseForm
      modalIsOpen={modalIsOpen}
      modalMessage={modalMessage}
      stepIndicatorsProps={stepIndicatorsProps(dataFetch)}
      isLoading={isLoading}
      setModalIsOpen={setModalIsOpen}
    >
      {dataFetch && formVals && (
        <>
          <VehicleForm
            formVals={formVals}
            setFormVals={setFormVals}
            errors={errors}
            setErrors={setErrors}
            disabled={!((isFurtherInfoStep(dataFetch) || dataFetch.isDraft) && dataFetch.isAuthorized)}
          />
          <GridTriple>
            {(isFurtherInfoStep(dataFetch) || dataFetch.isDraft) &&
              !dataFetch.attachments.some(
                (att) => att.filename.split('@')[1] === 'offerAttachment',
              ) && (
                <FileUploadInput
                  labelText='Ladda upp offert'
                  buttonText='Ladda upp offert'
                  inputId='offerAttachment'
                  processId={dataFetch.id}
                />
              )}
            {(isFurtherInfoStep(dataFetch) || dataFetch.isDraft) &&
              !dataFetch.attachments.some(
                (att) => att.filename.split('@')[1] === 'calculationAttachment',
              ) && (
                <FileUploadInput
                  labelText='Ladda upp personalbilskalkyl'
                  buttonText='Ladda upp kalkyl'
                  inputId='calculationAttachment'
                  processId={dataFetch.id}
                />
              )}
            {(isFurtherInfoStep(dataFetch) || dataFetch.isDraft) &&
              !dataFetch.attachments.some(
                (att) => att.filename.split('@')[1] === 'agreementAttachment',
              ) && (
                <FileUploadInput
                  labelText='Ladda upp personalbilsavtal'
                  buttonText='Ladda upp avtal'
                  inputId='agreementAttachment'
                  processId={dataFetch.id}
                />
              )}
            {dataFetch.attachments.map((att) => {
              return (
                <Attachment
                  key={att.id}
                  inputId={att.filename.split('@')[1]}
                  processId={dataFetch.id}
                  attachments={dataFetch.attachments}
                  allowRemove={stepIndicatorsProps(dataFetch).currentStep === 1}
                  handleRemoveExistingAttachment={removeExistingAttachment}
                />
              )
            })}
          </GridTriple>
          <GridSingles title={'Restvärde'}>
            <InputText
              id={'residualValue'}
              label={
                formVals.category === 'Personalbil'
                  ? 'Restvärde i procent efter 36 månader (anges av biladministrationen)'
                  : 'Restvärde i procent efter 48 månader (anges av biladministrationen)'
              }
              text={formVals.residualValue}
              onChange={(e) => setUpdate({ id: 'residualValue', value: e })}
              disabled={!(isStep(dataFetch, DecisionMaker.CAR_ADMIN) && dataFetch.isAuthorized)}
            />
          </GridSingles>
          <GridSingles title={'Kommentarer'}>
            <InputTextArea
              id={'carAdminComment'}
              title={'Firmatecknare/Fullmaktshavare kommentar'}
              onChange={(e) => setUpdate({ id: "carAdminComment", value: e })}
              text={formVals.carAdminComment}
              disabled={!(isStep(dataFetch, DecisionMaker.CAR_ADMIN) && dataFetch.isAuthorized)}
            />
            <InputTextArea
              id={'hrComment'}
              title={'HR kommentar'}
              onChange={(e) => setUpdate({ id: "hrComment", value: e })}
              text={formVals.hrComment}
              disabled={!(isStep(dataFetch, DecisionMaker.HR) && dataFetch.isAuthorized)}
            />
          </GridSingles>
          <>
            {stepIndicatorsProps(dataFetch).stepName[
              stepIndicatorsProps(dataFetch).currentStep - 1
            ] !== 'Initierad av' && (
                <Grid title={'Underskifter'} errorMsgState={errors.submit}>
                  <InputText
                    label={'Underskrift av Förare'}
                    text={formVals.driverSignature ?? ''}
                    id={'driverSignature'}
                  />
                  <InputText
                    label={'Datum'}
                    text={formatDate(formVals.driverSignatureDate) ?? ''}
                    id={'driverSignatureDate'}
                  />
                  <InputText
                    label={'Underskrift av Närmaste chef'}
                    text={formVals.managerSignature ?? ''}
                    id={'managerSignature'}
                  />
                  <InputText
                    label={'Datum'}
                    text={formatDate(formVals.managerSignatureDate) ?? ''}
                    id={'managerSignatureDate'}
                  />
                  <InputText
                    label={'Underskrift av Firmatecknare/Fullmaktshavare'}
                    text={formVals.carAdminSignature ?? ''}
                    id={'carAdminSignature'}
                  />
                  <InputText
                    label={'Datum'}
                    text={formatDate(formVals.carAdminSignatureDate) ?? ''}
                    id={'carAdminSignatureDate'}
                  />
                  <InputText
                    label={'Underskrift av HR'}
                    text={formVals.hrSignature ?? ''}
                    id={'hrSignature'}
                  />
                  <InputText
                    label={'Datum'}
                    text={formatDate(formVals.hrSignatureDate) ?? ''}
                    id={'hrSignatureDate'}
                  />
                  <InputText
                    label={'Underskrift av Finance'}
                    text={formVals.financeSignature ?? ''}
                    id={'financeSignature'}
                  />
                  <InputText
                    label={'Datum'}
                    text={formatDate(formVals.financeSignatureDate) ?? ''}
                    id={'financeSignatureDate'}
                  />
                  <InputText
                    label={'Underskrift av VD'}
                    text={formVals.ceoSignature ?? ''}
                    id={'CeoSignature'}
                  />
                  <InputText
                    label={'Datum'}
                    text={formatDate(formVals.ceoSignatureDate) ?? ''}
                    id={'CeoSignatureDate'}
                  />
                </Grid>
              )}
          </>
          {!isCompleted(dataFetch) &&
            dataFetch.isAuthorized &&
            (showAdmin && isStep(dataFetch, DecisionMaker.MANAGER) ? (
              <FormButtonsWithOverride
                dataFetch={dataFetch}
                user={user}
                formVals={formVals}
                setFormVals={setFormVals}
                setModalIsOpen={setModalIsOpen}
                setModalMessage={setModalMessage}
                setIsLoading={setIsLoading}
                errors={errors}
                id={id}
              />
            ) : (
              <FormButtons
                dataFetch={dataFetch}
                user={user}
                formVals={formVals}
                setFormVals={setFormVals}
                setModalIsOpen={setModalIsOpen}
                setModalMessage={setModalMessage}
                setIsLoading={setIsLoading}
                errors={errors}
                id={id}
              />
            ))}

{isCompleted(dataFetch) && (
  <Row>
    <h4 style={{ color: Colors.RED }}>
      {dataFetch?.processDecision?.isDeclined ? (
        <>
          Processen är nekad
          <br />
          {`Kommentar för avslag: ${dataFetch?.decisions[dataFetch?.decisions.length - 1].comment}`}
        </>
      ) : (
        dataFetch?.processDecision?.isFinished && 'Processen är klar'
      )}
    </h4>
  </Row>
)}
        </>
      )}
    </BaseForm>
  )
}

export default VehicleExisting
