import { Link, useNavigate } from 'react-router-dom'
import homeImg from '../../../assets/home.webp'
import { useEffect, useState, useContext } from 'react'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import Button from '../../atoms/Button/Button'
import { requestMaker } from '../../../helpers/requestMaker'
import './AdminHeader.css'
// import './HomeMenu.css'


const AdminHeader = ({ linkPrefix }) => {
  var { user } = useContext(SimpleAuthContext)
  const navigate = useNavigate()
  const [dataFromFetch, setDataFromFetch] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        'GET',
        'User/GetUserPermission',
        localStorage.getItem('token')
      )
      setDataFromFetch(data)
    }
    fetchData()
  }, [])

  return (
    <>
      <div className='processesList'>
        <ul>
          <img
            alt="Home button"
            className='homeButton'
            src={homeImg}
            onClick={() => {
              navigate('/')
            }}
          />
          <li>
            <Link to={'/admin'}>SKAPA RAPPORT</Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default AdminHeader
