import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import BonusExisting from "../../organisms/BonusExisting/BonusExisting";
import BonusNew from "../../organisms/BonusNew/BonusNew";
import AdminRapport from "../../organisms/AdminRapport/AdminRapport";
import AdminHeader from "../../molecules/AdminHeader/AdminHeader";
import { SimpleAuthContext } from "../../../helpers/SimpleAuthProvider";
import { requestMaker } from "../../../helpers/requestMaker";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner"; // Importing the spinner

const STATUS = {
  LOADING: "loading",
  NOT_AUTHORIZED: "notAuthorized",
  VERIFIED: "verified",
};

const AdminPage = ({ setTitle }) => {
  const { id } = useParams();
  const [status, setStatus] = useState(STATUS.LOADING); 
  var { user } = useContext(SimpleAuthContext);
  const [dataFromFetch, setDataFromFetch] = useState({});

  useEffect(() => {
    setTitle("Admin sida");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        "GET",
        "User/GetUserRole",
        localStorage.getItem("token")
      );
      if (!data) {
        setStatus(STATUS.NOT_AUTHORIZED);
      } else {
        setDataFromFetch(data);
        setStatus(STATUS.VERIFIED);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {status === STATUS.LOADING && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",  // This will make the spinner centered vertically
          }}
        >
          <LoadingSpinner /> {/* Rendering the loading spinner */}
        </div>
      )}
      {status === STATUS.NOT_AUTHORIZED && <p>Du har inte behörighet för att se denna sidan.</p>}
      {status === STATUS.VERIFIED && (
        <div>
          <AdminHeader />
          <AdminRapport />
        </div>
      )}
    </>
  );
};

export default AdminPage;
