import React, { useContext, useEffect, useState } from 'react'
import Row from '../../atoms/Row/Row'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import BaseForm from '../BaseForm/BaseForm'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { requestAccessToken } from '../../../helpers/requestAccessToken'
import Colors from '../../../assets/Color'
import DecisionMaker from '../../../assets/DecisionMaker'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputText from '../../molecules/Input/InputText'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import { updateFormVals } from '../../../helpers/update'
import formatDate from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import {
  stepIndicatorsProps,
  isCompleted,
  isFurtherInfoStep,
  isStep,
} from '../../../helpers/formFunctions'
import BonusForm from '../BonusForm/BonusForm'
import FormButtons from '../FormButtons/FormButtons'
import FormButtonsWithOverride from '../FormButtons/FormButtonsWithOverride'

const BonusExisting = ({ id }) => {
  const [update, setUpdate] = useState('init')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [dataFetch, setDataFetch] = useState()
  const [modalMessage, setModalMessage] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)
  const [formVals, setFormVals] = useState()
  const [errors, setErrors] = useState('')
  const [showAdmin, setShowAdmin] = useState(false);
  const [dataFromFetch, setDataFromFetch] = useState({});

  const navigate = useNavigate()
  const { instance, accounts } = useMsal()
  var { user } = useContext(SimpleAuthContext)

  useEffect(() => {
    if (!dataFetch) return

    var vals = { ...dataFetch.data }
    vals.createdAt = dataFetch.createdAt
    vals.initiatorEmail = dataFetch.initiator.email
    setFormVals(vals)
  }, [dataFetch])

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        "GET",
        "User/GetUserRole",
        localStorage.getItem("token")
      );
      if (data) {
        setShowAdmin(true);
      }
      setDataFromFetch(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      requestAccessToken(instance, accounts)
      token = localStorage.getItem('token')
    }
    async function fetcher() {
      setIsLoading(true)
      const dataFromRequest = await requestMaker(
        user,
        'GET',
        `process/${id}`,
        token,
      )

      setIsLoading(false)
      if (dataFromRequest?.errorMessage === 'error') {
        setModalIsOpen(true)
        setModalMessage(dataFromRequest?.result?.message)
      }
      if (!dataFromRequest) navigate('/')
      setDataFetch(dataFromRequest)
    }
    fetcher()
  }, [navigate, id, /*accounts, Put back when using MSAL */ instance])

  useEffect(() => {
    if (update == 'init')
      return

    updateFormVals(update, formVals, setFormVals)
  }, [update])
var decisionStep = (isStep(dataFetch, DecisionMaker.HR) && dataFetch.isAuthorized || isStep(dataFetch, DecisionMaker.MANAGER) && dataFetch.isAuthorized)

  return (
    <BaseForm
      modalIsOpen={modalIsOpen}
      modalMessage={modalMessage}
      stepIndicatorsProps={stepIndicatorsProps(dataFetch)}
      isLoading={isLoading}
      setModalIsOpen={setModalIsOpen}
    >
      {dataFetch && formVals && (
        <>
          <BonusForm
            formVals={formVals}
            setFormVals={setFormVals}
            errors={errors}
            setErrors={setErrors}
            decisionStep = {decisionStep}
            disabled={!((isFurtherInfoStep(dataFetch) || dataFetch.isDraft) && dataFetch.isAuthorized)}
          />

          <GridSingles title={'Kommentarer'}>
            <InputTextArea
              id={'hrComment'}
              title={'HR kommentar'}
              onChange={(e) => setUpdate({ id: "hrComment", value: e })}
              text={formVals.hrComment}
              disabled={!(isStep(dataFetch, DecisionMaker.HR) && dataFetch.isAuthorized)}
            />
            <InputTextArea
              id={'payrollComment'}
              title={'Payroll kommentar'}
              onChange={(e) => setUpdate({ id: "payrollComment", value: e })}
              text={formVals.payrollComment}
              disabled={!(isStep(dataFetch, DecisionMaker.PAYROLL) && dataFetch.isAuthorized)}
            />
          </GridSingles>
          <Grid title={'Underskrift'}>
            <InputText
              id={'HrSignature'}
              label={'Underskrift av HR'}
              text={formVals.hrSignature}
            />
            <InputText
              id={'HrSignatureDate'}
              label={'Underskrift datum'}
              text={formatDate(formVals.hrSignatureDate)}
            />
            <InputText
              id={'payrollSignature'}
              label={'Underskrift av Payroll'}
              text={formVals.payrollSignature}
            />
            <InputText
              id={'payrollSignatureDate'}
              label={'Underskrift datum'}
              text={formatDate(formVals.payrollSignatureDate)}
            />
          </Grid>

          {!isCompleted(dataFetch) &&
            dataFetch.isAuthorized &&
            (showAdmin && isStep(dataFetch, DecisionMaker.MANAGER) ? (
              <FormButtonsWithOverride
                dataFetch={dataFetch}
                user={user}
                formVals={formVals}
                setFormVals={setFormVals}
                setModalIsOpen={setModalIsOpen}
                setModalMessage={setModalMessage}
                setIsLoading={setIsLoading}
                errors={errors}
                id={id}
              />
            ) : (
              <FormButtons
                dataFetch={dataFetch}
                user={user}
                formVals={formVals}
                setFormVals={setFormVals}
                setModalIsOpen={setModalIsOpen}
                setModalMessage={setModalMessage}
                setIsLoading={setIsLoading}
                errors={errors}
                id={id}
              />
            ))}
        </>
      )
      }
    </BaseForm>
  )
}

export default BonusExisting
