import React, { useContext, useEffect, useState } from "react";
import Row from "../../atoms/Row/Row";
import { requestMaker } from "../../../helpers/requestMaker";
import { SimpleAuthContext } from "../../../helpers/SimpleAuthProvider";
import VipForm from "../VipForm/VipForm";
import BaseForm from "../BaseForm/BaseForm";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { requestAccessToken } from "../../../helpers/requestAccessToken";
import Colors from "../../../assets/Color";
import DecisionMaker from "../../../assets/DecisionMaker";
import GridSingles from "../../atoms/Grid/GridSingles";
import InputTextArea from "../../molecules/InputTextArea/InputTextArea";
import { updateFormVals } from "../../../helpers/update";
import {
  isCompleted,
  isFurtherInfoStep,
  isStep,
  stepIndicatorsProps,
} from "../../../helpers/formFunctions";
import FormButtons from "../FormButtons/FormButtons";
import FormButtonsWithOverride from "../FormButtons/FormButtonsWithOverride";

const VipExisting = ({ id }) => {
  const [update, setUpdate] = useState("init");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataFetch, setDataFetch] = useState();
  const [modalMessage, setModalMessage] = useState("initial");
  const [isLoading, setIsLoading] = useState(false);
  const [formVals, setFormVals] = useState();
  const [errors, setErrors] = useState("");
  const [showAdmin, setShowAdmin] = useState(false);
  const [dataFromFetch, setDataFromFetch] = useState({});

  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  var { user } = useContext(SimpleAuthContext);

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        "GET",
        "User/GetUserRole",
        localStorage.getItem("token")
      );
      if (data) {
        setShowAdmin(true);
      }
      setDataFromFetch(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!dataFetch) return;
    var vals = { ...dataFetch.data };
    vals.createdAt = dataFetch.createdAt;
    vals.initiatorEmail = dataFetch.initiator.email;
    vals.backToLatest = false;
    setFormVals(vals);
  }, [dataFetch]);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      requestAccessToken(instance, accounts);
      token = localStorage.getItem("token");
    }
    async function fetcher() {
      setIsLoading(true);
      const dataFromRequest = await requestMaker(
        user,
        "GET",
        `process/${id}`,
        token
      );
      setIsLoading(false);
      if (dataFromRequest?.errorMessage === "error") {
        setModalIsOpen(true);
        setModalMessage(dataFromRequest?.result?.message);
      }
      if (!dataFromRequest) navigate("/");
      setDataFetch(dataFromRequest);
    }
    fetcher();
  }, [navigate, id, /*accounts, Put back when using MSAL */ instance]);

  useEffect(() => {
    if (update == "init") return;

    updateFormVals(update, formVals, setFormVals);
  }, [update]);

  return (
    <BaseForm
      modalIsOpen={modalIsOpen}
      modalMessage={modalMessage}
      stepIndicatorsProps={stepIndicatorsProps(dataFetch)}
      isLoading={isLoading}
      setModalIsOpen={setModalIsOpen}
    >
      {dataFetch && formVals && (
        <>
          <VipForm
            formVals={formVals}
            setFormVals={setFormVals}
            errors={errors}
            setErrors={setErrors}
            disabled={
              !(
                (isFurtherInfoStep(dataFetch) || dataFetch.isDraft) &&
                dataFetch.isAuthorized
              )
            }
          />
          <GridSingles title={"Kommentarer"}>
            <InputTextArea
              id={"lineManagerComment"}
              title={"Chef kommentar"}
              onChange={(e) =>
                setUpdate({ id: "lineManagerComment", value: e })
              }
              text={formVals.lineManagerComment}
              disabled={
                !(
                  isStep(dataFetch, DecisionMaker.MANAGER) &&
                  dataFetch.isAuthorized
                )
              }
            />
            <InputTextArea
              id={"hrComment"}
              title={"HR kommentar"}
              onChange={(e) => setUpdate({ id: "hrComment", value: e })}
              text={formVals.hrComment}
              disabled={
                !(isStep(dataFetch, DecisionMaker.HR) && dataFetch.isAuthorized)
              }
            />
            <InputTextArea
              id={"payrollComment"}
              title={"Payroll kommentar"}
              onChange={(e) => setUpdate({ id: "payrollComment", value: e })}
              text={formVals.payrollComment}
              disabled={
                !(
                  isStep(dataFetch, DecisionMaker.PAYROLL) &&
                  dataFetch.isAuthorized
                )
              }
            />
          </GridSingles>

          {!isCompleted(dataFetch) &&
            dataFetch.isAuthorized &&
            (showAdmin && isStep(dataFetch, DecisionMaker.MANAGER) ? (
              <FormButtonsWithOverride
                dataFetch={dataFetch}
                user={user}
                formVals={formVals}
                setFormVals={setFormVals}
                setModalIsOpen={setModalIsOpen}
                setModalMessage={setModalMessage}
                setIsLoading={setIsLoading}
                errors={errors}
                id={id}
              />
            ) : (
              <FormButtons
                dataFetch={dataFetch}
                user={user}
                formVals={formVals}
                setFormVals={setFormVals}
                setModalIsOpen={setModalIsOpen}
                setModalMessage={setModalMessage}
                setIsLoading={setIsLoading}
                errors={errors}
                id={id}
              />
            ))}

{isCompleted(dataFetch) && (
  <Row>
    <h4 style={{ color: Colors.RED }}>
      {dataFetch?.processDecision?.isDeclined ? (
        <>
          Processen är nekad
          <br />
          {`Kommentar för avslag: ${dataFetch?.decisions[dataFetch?.decisions.length - 1].comment}`}
        </>
      ) : (
        dataFetch?.processDecision?.isFinished && 'Processen är klar'
      )}
    </h4>
  </Row>
)}

        </>
      )}
    </BaseForm>
  );
};

export default VipExisting;
