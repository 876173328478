import React, { useState, useEffect, useContext } from "react";
import { requestMaker, requestMakerBlob } from "../../../helpers/requestMaker";
import { useMsal } from "@azure/msal-react"; // Assuming you're using MSAL for authentication
import { SimpleAuthContext } from "../../../helpers/SimpleAuthProvider";
import "./AdminRapport.css";

export default function AdminRapport() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [typesOfProcesses, setTypesOfProcesses] = useState([]); // Store fetched process types (main categories)
  const [children, setChildren] = useState({}); // Store children (subcategories) for each process
  const [selectedProcesses, setSelectedProcesses] = useState([]); // Selected main processes
  const [selectedChildren, setSelectedChildren] = useState({}); // Selected child fields (subcategories)
  const [expandedProcesses, setExpandedProcesses] = useState([]); // Track which processes are expanded for selection

  const { instance, accounts } = useMsal(); // Hook to access MSAL instance and accounts
  var { user } = useContext(SimpleAuthContext);

  // Fetch the process types (main categories) and their subcategories on component mount
  useEffect(() => {
    const fetchProcessTypes = async () => {
      try {
        const ep = "Rapport/RapportSubCategories";
        const token = localStorage.getItem("token"); // Assuming you store your token in localStorage
        const data = await requestMaker(user, "GET", ep, token);

        // Set the process types based on the keys of the response object (e.g., "Vip", "Overtime", etc.)
        const processKeys = Object.keys(data); // This gives ["Vip", "Overtime", "SuggestionBox", etc.]

        setTypesOfProcesses(processKeys); // Store the process keys (main categories)
        setChildren(data); // Store the actual process data (subcategories) as children
      } catch (error) {
        console.error("Error fetching process types:", error);
      }
    };

    fetchProcessTypes();
  }, [user]);

  // Handle the checkbox selection for main processes
  const handleProcessChange = (process) => {
    if (selectedProcesses.includes(process)) {
      setSelectedProcesses((prevSelected) =>
        prevSelected.filter((item) => item !== process)
      );
      // Deselect all children when the process is deselected
      setSelectedChildren((prevChildren) => ({
        ...prevChildren,
        [process]: [],
      }));
    } else {
      setSelectedProcesses((prevSelected) => [...prevSelected, process]);
      // Select all children when the process is selected
      setSelectedChildren((prevChildren) => ({
        ...prevChildren,
        [process]: Object.keys(children[process]), // Select all child fields for the process
      }));
    }
  };

  // Handle the checkbox selection for individual children
  const handleChildChange = (process, child) => {
    setSelectedChildren((prevSelected) => {
      const updatedChildren = prevSelected[process] || [];
      if (updatedChildren.includes(child)) {
        // Deselect child
        return {
          ...prevSelected,
          [process]: updatedChildren.filter((item) => item !== child),
        };
      } else {
        // Select child
        return {
          ...prevSelected,
          [process]: [...updatedChildren, child],
        };
      }
    });
  };

  // Toggle expand/collapse for showing children of a selected process
  const toggleExpand = (process) => {
    setExpandedProcesses((prevExpanded) =>
      prevExpanded.includes(process)
        ? prevExpanded.filter((item) => item !== process)
        : [...prevExpanded, process]
    );
  };

  // Unselect all children for a specific process
  const unselectAllChildren = (process) => {
    setSelectedChildren((prevChildren) => ({
      ...prevChildren,
      [process]: [], // Set to an empty array
    }));
  };

  // Select all children for a specific process
  const selectAllChildren = (process) => {
    setSelectedChildren((prevChildren) => ({
      ...prevChildren,
      [process]: Object.keys(children[process]), // Select all child fields
    }));
  };

  // Function to generate the report and send the payload to the API
  const generateRapport = async () => {
    const keyValuePairs = {};

    selectedProcesses.forEach((process) => {
      keyValuePairs[process] = selectedChildren[process] || [];
    });

    const payload = {
      keyValuePairs: keyValuePairs,
      fromTime: fromDate,
      toTime: toDate,
      email: user.email,
    };

    try {
      const token = localStorage.getItem("token");
      const endpoint = "Rapport/GenerateRapport";

      const response = await requestMakerBlob(
        user,
        "POST",
        endpoint,
        token,
        payload
      );

      if (response.errorMessage || response.internalError) {
        console.error("Failed to generate the report:", response);
      } else {
        var data = new Blob([response], { type: "xlsx" });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;

        tempLink.setAttribute(
          "download",
          `Df-Sammanfattning ${fromDate} till ${toDate}.xlsx`
        );
        tempLink.click();
        tempLink.remove();
        window.URL.revokeObjectURL(csvURL);
        // console.log("Report generated successfully!", response);
      }
    } catch (error) {
      console.error("Error generating the report:", error);
    }
  };

  return (
    <div className="formCard">
      <form className="form">
        <h2 className="sectionHeader">Datum</h2>
        <label>Välj ett tidsintervall för rapporten:</label>
        <div className="dateStyle">
          <div>
            <label className="date-spacing">
              Från:
              <input
                style={{ marginLeft: 10 }}
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>
              Till:
              <input
                style={{ marginLeft: 10 }}
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </label>
          </div>
        </div>

        <h3 className="sectionHeader">Processer</h3>
        <label>Välj de processerna ni vill skapa en rapport för:</label>

        {/* Map typesOfProcesses to display the main categories */}
        {typesOfProcesses.map((process, index) => (
          <div className="processListItem" key={index}>
            <label>
              <input
                type="checkbox"
                value={process}
                checked={selectedProcesses.includes(process)}
                onChange={() => handleProcessChange(process)}
              />
              {" " + process}
              <span>
                (
                {selectedChildren[process]
                  ? selectedChildren[process].length
                  : 0}
                /{children[process] ? Object.keys(children[process]).length : 0}
                )
              </span>
            </label>

            {selectedProcesses.includes(process) && (
              <>
                <button
                  type="button"
                  className="admin-button-process"
                  onClick={() => toggleExpand(process)}
                  style={{ marginLeft: "30px" }}
                >
                  {expandedProcesses.includes(process)
                    ? "Dölj avancerad selektering"
                    : "Visa avancerad selektering"}
                </button>

                {expandedProcesses.includes(process) && (
                  <div className="childCheckboxContainer">
                    <div>
                      <button
                        className="admin-button-process"
                        type="button"
                        onClick={() => selectAllChildren(process)}
                        style={{ marginRight: 10, width: 150 }}
                      >
                        Markera alla
                      </button>
                      <button
                        className="admin-button-process"
                        type="button"
                        onClick={() => unselectAllChildren(process)}
                      >
                        Avmarkera alla
                      </button>
                    </div>
                    <br></br>

                    {Object.keys(children[process] || {}).map(
                      (child, childIndex) => (
                        <div key={childIndex} className="childCheckbox">
                          <label>
                            <input
                              type="checkbox"
                              value={child}
                              checked={selectedChildren[process]?.includes(
                                child
                              )}
                              onChange={() => handleChildChange(process, child)}
                            />
                            {" " + child}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
        <br />
        <button
          className="mainButton"
          type="button"
          style={{ width: 50 }}
          onClick={generateRapport}
        >
          Generera rapport
        </button>
      </form>
    </div>
  );
}
