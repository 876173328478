export const requestMaker = async (
  user, //Remove when using MSAL again
  typeOfRequest,
  url,
  accessToken,
  data,
  contentType = 'application/json'
) => {
  var requestOptions = {
    method: typeOfRequest,
    headers: {
      'Content-Type': contentType,
      'Access-Control-Expose-Headers': 'Content-Disposition',
      firstName: encodeURIComponent(user.firstName), //Remove when using MSAL again
      lastName: encodeURIComponent(user.lastName), //Remove when using MSAL again
      email: user.email, //Remove when using MSAL again
    },
  }
  // put back when using MSAL again
  // if (accessToken) {
  //   const bearer = `Bearer ${accessToken}`
  //   requestOptions.headers.Authorization = bearer
  // }
  if (data) {
    requestOptions.body = JSON.stringify(data)
  }
  try {
    let response = await fetch(
      process.env.REACT_APP_SERVER_URL + url,
      requestOptions
    )
    if (!response.ok) {
      try {
        const result = await response.json()
        return { errorMessage: 'error', result }
      } catch (error) {
        return { internalError: error }
      }
    }
    try {
      const result = await response.json()
      return result
    } catch (error) {
      return { internalError: error }
    }
  } catch (error) {
    return 'Failed to fetch'
  }
}

export const requestMakerBlob = async (
  user, //Remove when using MSAL again
  typeOfRequest,
  url,
  accessToken,
  data,
  contentType = 'application/json'
) => {
  var requestOptions = {
    method: typeOfRequest,
    headers: {
      'Content-Type': contentType,
      'Access-Control-Expose-Headers': 'Content-Disposition',
      firstName: encodeURIComponent(user.firstName), //Remove when using MSAL again
      lastName: encodeURIComponent(user.lastName), //Remove when using MSAL again
      email: user.email, //Remove when using MSAL again
    },
  }
  if (data) {
    requestOptions.body = JSON.stringify(data)
  }
  try {
    let response = await fetch(
      process.env.REACT_APP_SERVER_URL + url,
      requestOptions
    )
    if (!response.ok) {
      try {
        const result = response.blob()
        return { errorMessage: 'error', result }
      } catch (error) {
        return { internalError: error }
      }
    }
    try {
      const result = response.blob()
      return result
    } catch (error) {
      return { internalError: error }
    }
  } catch (error) {
    return 'Failed to fetch'
  }
}


export const fileUploader = async (
  user, //Remove when using msal again
  url,
  accessToken,
  inputId,
  processId,
  data
) => {
  try {
    const formData = new FormData()
    formData.append('inputId', inputId)
    formData.append('processId', processId)
    if (data) formData.append('file', data)
    let response = fetch(process.env.REACT_APP_SERVER_URL + url, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Credentials': true,
        firstName: user.firstName, //Remove when using MSAL again
        lastName: user.lastName, //Remove when using MSAL again
        email: user.email, //Remove when using MSAL again
        // Authorization: `Bearer ${accessToken}`, Put back when using MSAL again
      },
      body: formData,
    })
    if (!response.ok) {
      try {
        const result = await response.json()
        return { errorMessage: 'error', result }
      } catch (error) {
        return { internalError: error }
      }
    }
    try {
      const result = await response.json()
      return result
    } catch (error) {
      return { internalError: error }
    }
  } catch (error) {
    return 'Failed to upload file'
  }
}
export const downloader = async (user, url, accessToken) => {
  const result = await fetch(process.env.REACT_APP_SERVER_URL + url, {
    method: 'GET',
    headers: {
      'Access-Control-Expose-Headers': 'Content-Disposition',
      firstName: user.firstName, //Remove when using MSAL again
      lastName: user.lastName, //Remove when using MSAL again
      email: user.email, //Remove when using MSAL again
      // Authorization: `Bearer ${accessToken}`, Put back when using MSAL again
    },
  })

  const fileName = result.headers
    .get('content-disposition')
    ?.split('=')[1]
    ?.split('@')[0]
    ?.split(';')[0]

  const blob = await result.blob()
  return { blob, fileName }
}

export const remover = async (user, url, accessToken) => {
  const result = await fetch(process.env.REACT_APP_SERVER_URL + url, {
    method: 'DELETE',
    headers: {
      'Access-Control-Expose-Headers': 'Content-Disposition',
      firstName: user.firstName, //Remove when using MSAL again
      lastName: user.lastName, //Remove when using MSAL again
      email: user.email, //Remove when using MSAL again
      // Authorization: `Bearer ${accessToken}`, Put back when using MSAL again
    },
  })
}
