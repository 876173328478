import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import SupportEmailModal from "../../organisms/SupportEmail/SupportEmailModal";
import HomeMenu from "../../molecules/HomeMenu/HomeMenu";
import styles from "./Home.css";
import { requestMaker } from "../../../helpers/requestMaker";
import { SimpleAuthContext } from "../../../helpers/SimpleAuthProvider";
import DecisionMaker from "../../../assets/DecisionMaker";

const Home = ({ setTitle }) => {
  const [dataFromFetch, setDataFromFetch] = useState({});
  var { user } = useContext(SimpleAuthContext);

  const [showAdmin, setShowAdmin] = useState(false);

  const [dataFetch, setDataFetch] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        "GET",
        "User/GetUserRole",
        localStorage.getItem("token")
      );
      if (data) {
        setShowAdmin(true);
      }
      setDataFromFetch(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setTitle("");
  }, []);
  return (
    <>
      <SupportEmailModal />
      <HomeMenu />
      <div className="homeContainer">
        <ul>
          <h3 className={styles}>Välj process</h3>
          <Link to="/vip">
            <li>VIP - Value In People</li>
          </Link>
          <Link to="/vehicle">
            <li>Bilbeställning</li>
          </Link>
          <Link to="/leaveApplication">
            <li>Ledighetsansökan</li>
          </Link>
          <Link to="/recruiting">
            <li>Finders Fee - Rekryteringsbonus</li>
          </Link>
          <Link to="/overtime">
            <li>Ansökan - Extra övertid</li>
          </Link>
          <Link to="/suggestionBox">
            <li>Förslagslåda</li>
          </Link>
          <Link to="/bonus">
            <li>Ansökan - Certifieringsbonus</li>
          </Link>

          {showAdmin ? (
            <Link to="/admin">
              <li>Admin portal</li>
            </Link>
          ) : null}

          {/* <Link to="/admin">
            <li>
              Admin portal
            </li>
          </Link> */}
        </ul>
      </div>
    </>
  );
};

export default Home;
